<template>
  <div class="zone">
    <functionBox  :titleData="{permission_flag:'Samples',nowID:1}" @searchBtn="searchBtn" :searhLoading="loading">
      <template>
        <li>
          <ProvinceSelect @selectedProvince="handleSelectedProvince"></ProvinceSelect>
        </li>
        <li>
          <el-select
            v-model="city_id"
            placeholder="请选择地市"
            size="small"
            filterable
            clearable
          >
            <el-option
              v-for="item in optionsCity"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </li>
        <li>
          <el-select
            v-model="hospital_id"
            placeholder="请输入医院"
            size="small"
            filterable
            clearable
            :remote="province_id==''"
            :remote-method="remoteMethod"
          >
            <el-option
              v-for="item in hospitalList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </li>
        <li>
          <timePicker @setTime="setTime" radioParent="年"></timePicker>
        </li>
      </template>
    </functionBox>
    <!-- <div class="indent">
      <div class="below">
        <div class="city">
          <el-select
            v-model="province_id"
            placeholder="请选择省份"
            size="small"
            filterable
            clearable
            @change='changeProvince'
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="city">
          <el-select
            v-model="city_id"
            placeholder="请选择地市"
            size="small"
            filterable
            clearable
          >
            <el-option
              v-for="item in optionsCity"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="city">
        <el-select
            v-model="hospital_id"
            placeholder="请输入医院"
            size="small"
            filterable
            clearable
            remote
            :remote-method="remoteMethod"
          >
            <el-option
              v-for="item in hospitalList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="calendarone">
          <div class="title">时间：</div>
          <el-radio-group v-model="radio" size="small">
            <el-radio-button label="年"></el-radio-button>
            <el-radio-button label="月"></el-radio-button>
            <el-radio-button label="日"></el-radio-button>
          </el-radio-group>
        </div>
        <div class="calendar">
          <div v-if="radio === '年'" class="el_calendar_year">
            <el-date-picker
              v-model="start_stats_year"
              type="year"
              :placeholder="startPlaceholder"
              :format="format"
              :value-format="formatValue"
              size="small"
              @change="changeYearData"
            ></el-date-picker>
            ～
            <el-date-picker
              v-model="end_stats_year"
              type="year"
              :placeholder="endPlaceholder"
              :format="format"
              :value-format="formatValue"
              size="small"
              @change="changeYearData"
            ></el-date-picker>
          </div>
          <el-date-picker
            v-else
            ref="datedate"
            size="small"
            v-model="dateMonthValue"
            :format="format"
            :value-format="formatValue"
            :type="type"
            :start-placeholder="startPlaceholder"
            :end-placeholder="endPlaceholder"
            class="Calendar"
          />
        </div>
        <div class="seachone">
          <el-button type="primary" size="small" @click="searchBtn" :disabled="!chartShow" :loading="!chartShow">
            搜索
          </el-button>
        </div>
      </div>
    </div> -->
    <div v-if="ehartList.length<=0" style="color:#666;text-align:center;">暂无数据</div>
    <div v-if="chartShow">
      <div class="diagram" v-for="(item, index) of ehartList" :key="index" >
        <div class="diagramRight">
          <p class="title">{{ item.testing_unique + '阴阳性占比' }}</p>
          <ul class="timeTab" :style="item.btn == 1?'borderColor: #F73A75':''">
            <li
              @click="changeBtn(0, item.testing_unique)"
              :class="item.btn == 0 ? 'activeBtn' : ''"
            >
              历史平均
            </li>
            <li
              @click="changeBtn(1, item.testing_unique)"
              :class="item.btn == 1 ? 'activeBtn colorCla' : ''"
            >
              当前平均
            </li>
          </ul>
          <SamplesLineEchart
            v-if="item.btn == 0"
            :id="'SamplesLineEchartA' + item.testing_unique"
            markLineColor="#1ACB64"
            :ehartData="item.seriesDataB"
          />
          <SamplesLineEchart
            v-if="item.btn == 1"
            :id="'SamplesLineEchartANow' + item.testing_unique"
            markLineColor="#F73A75"
            :ehartData="item.seriesDataA"
          />
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import api from '@/api/tongji'
// import SamplesPieEchart from '@/components/SamplesPieEchart.vue'
import SamplesLineEchart from '@/components/SamplesLineEchart.vue'
import functionBox from './components/functionBox.vue'
import timePicker from '@/components/timePicker'
import { hospitals_list } from '@/api/search.js'
import linkageMixins from './components/linkageMixins.js'
import ProvinceSelect from "@/components/ProvinceSelect.vue";

export default {
  name: 'Samples',
  mixins: [linkageMixins],
  components: {ProvinceSelect, timePicker, SamplesLineEchart,functionBox },
  data() {
    return {
      province_id: '',
      city_id: '',
      hospital_id: '', //医院下拉框列表-值
      hospitalList: [], //医院下拉框列表
      options: JSON.parse(localStorage.getItem('province')),
      optionsCity: [],
      radio: '年', //时间节点
      start_stats_year: '', //开始年份
      end_stats_year: '', //结束年份
      dateMonthValue: '', //月份和日期选择
      ehartListNow: [],
      ehartList: [],
      yyyy: '',
      MM: '',
      dd: '',
      chartShow: false,
      loading: false,
    }
  },
  computed: {
    type() {
      const arr = {
        年: 'year',
        月: 'monthrange',
        日: 'daterange',
      }
      return arr[this.radio]
    },
    startPlaceholder() {
      const arr = {
        年: '开始年份',
        月: '开始月份',
        日: '开始日期',
      }
      return arr[this.radio]
    },
    endPlaceholder() {
      const arr = {
        年: '结束年份',
        月: '结束月份',
        日: '结束日期',
      }
      return arr[this.radio]
    },
    format() {
      const arr = {
        年: 'yyyy',
        月: 'yyyy/MM',
        日: 'yyyy/MM/dd',
      }
      return arr[this.radio]
    },
    formatValue() {
      const arr = {
        年: 'yyyy',
        月: 'yyyyMM',
        日: 'yyyyMMdd',
      }
      return arr[this.radio]
    },
  },
  watch: {
    type(val) {
      const yyyy = this.yyyy+'',
            MM = this.MM,
            dd = this.dd
      switch (val) {
        case 'year':
          this.start_stats_year = yyyy
          this.end_stats_year = yyyy
          break;
        case 'monthrange':
          this.dateMonthValue = [yyyy+'01',yyyy+MM]
          break;
        case 'daterange':
          this.dateMonthValue = [yyyy+MM+'01',yyyy+MM+dd]
          break;
        default:
          break;
      }
    },
  },
  mounted() {
    this.positive_rateFun();
    // this.positive_rate_nowFun()
    const nowdate  = new Date();
    this.yyyy =this.start_stats_year = this.end_stats_year = nowdate.getFullYear() + '';
    let MM = nowdate.getMonth()+1,
        dd = nowdate.getDate();
    this.MM = MM<=9? '0'+MM : MM
    this.dd = dd<=9? '0'+dd : dd
  },

  methods: {
    handleSelectedProvince(val){
      this.province_id = val
      this.changeProvince(val)
    },
    // 医院远程搜索
    remoteMethod(e) {
      this.hospitalList = hospitals_list({ hospital_name: e })
    },
    // 根据回调修改当前页的相关属性
    setTime(val) {
      this[val.name] = val.value
    },
    // 列表
    positive_rateFun() {
      this.loading = true;
      let param = {
        province_id: this.province_id, //省份标识
        city_id: this.city_id,
        hospital_id: this.hospital_id,
        ...this.filterQuery(),
      }
      this.chartShow = false
      api.positive_rate(param).then((res) => {
        let data = res.data
        data.forEach((element) => {
          let yData =  element.positive_list,
              xData = element.stats_time,
							his_positive = element.his_positive,
							now_positive = element.now_positive;

					element.seriesDataB = {
						xData,
						yData,
						his_positive,
					}
          element.seriesDataA = {
						xData,
						yData,
						his_positive: now_positive
					}
          element.btn = 0
        })
        this.ehartList = data
      }).finally(msg => {
        this.loading = false;
        setTimeout(()=> {
          this.chartShow = true;
        },1000)
      })
    },
    // 列表-今年
    positive_rate_nowFun() {
      let param = {
        province_id: this.province_id, //省份标识
        city_id: this.city_id,
        hospital_id: this.hospital_id
      }
      api.now_positive_rate(param).then((res) => {
        let data = res.data
        data.forEach((element) => {
          let oldY =  element.now_positive_list,
							dataX = element.stats_time,
							his_positive = element.his_positive;
					element.seriesDataB = {
						xData: dataX,
						yData: oldY,
						his_positive: his_positive
					}
          element.btn = 0
        })
        this.ehartListNow = data
      })
    },
    // 跳转页面携带参数处理
    filterQuery() {
      let param = {
        date_type: '', //日期类型:0:年,1,月,2,日
      }
      let start_day,end_day;
      if (this.radio == '年') {
        param.start_stats_year = start_day = 
        this.start_stats_year != null ? this.start_stats_year : ''
        param.end_stats_year = end_day = 
        this.end_stats_year != null ? this.end_stats_year : ''
        param.date_type = 0
      } else if (this.radio == '月') {
        param.start_stats_month = start_day =
          this.dateMonthValue != null ? this.dateMonthValue[0] : ''
        param.end_stats_month = end_day =
          this.dateMonthValue != null ? this.dateMonthValue[1] : ''
        param.date_type = 1
      } else if (this.radio == '日') {
        param.start_stats_day = start_day = 
          this.dateMonthValue != null ? this.dateMonthValue[0] : ''
        param.end_stats_day = end_day =
          this.dateMonthValue != null ? this.dateMonthValue[1] : ''
        param.date_type = 2
      }
      if(start_day == '' || start_day == undefined ){
        let date = new Date();
        // param.start_stats_year =  param.end_stats_year = start_day = end_day = date.getFullYear()
        let yyyy = date.getFullYear() + '';
        let MM = date.getMonth()+1;
        MM = MM<=9 ? '0'+MM : MM;
        param.start_stats_month =  start_day = yyyy + '01';
        param.end_stats_month =  end_day = yyyy+ MM;
      }
      param.date_type = this.$tool.dateType(start_day,end_day);
      return param
    },

    // 年份联动选择处理,开始年份>结束年份
    changeYearData() {
      if (
        this.end_stats_year != '' &&
        this.start_stats_year > this.end_stats_year
      ) {
        this.end_stats_year = '' //结束年份
      }
    },
    // 省份改变
    changeProvince(val) {
      this.optionsCity = this.options.find(currentValue=>currentValue.value==val).city_list
      this.city_id = '';
      this.hospitalList = this.provinceHospitalList.find(e=> this.province_id==e.province_id).hospital_list.map(e=> {
        return {
          value: e.hospital_id,
          label: e.hospital_full_name,
        }
      })
      this.hospital_id='';
    },
    // 搜索
    searchBtn() {
      this.positive_rateFun()
      // this.positive_rate_nowFun()
    },

    // 切换历年，今年 折线图
    changeBtn(value, name) {
      this.ehartList.forEach((e) => {
        if (e.testing_unique == name) {
          e.btn = value
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-date-editor .el-range__icon {
  display: none;
}

.indent {
  height: px(107);
  background: #ffffff;
  box-shadow: 0px 2px 10px 0px rgba(51, 64, 79, 0.1);
  border-radius: 5px;
  margin-bottom: px(20);
  padding: px(15);
  display: flex;
  align-items: center;
}
.left1 {
  justify-content: flex-start;
  display: flex;
  align-items: center;
  font-size: px(20);
  font-weight: bold;
  color: #111111;
}
.toDevice {
  justify-content: flex-start;
  display: flex;
  align-items: center;
  font-size: px(20);
  font-weight: bold;
  color: #666666ff;
  margin-right: px(20);
}

.line {
  width: 1px;
  height: 13px;
  background-color: rgba(208, 215, 224, 1);
  margin-top: px(16);
  margin-right: px(20);
}

.right {
  flex: 1;
  justify-content: flex-end;
  display: flex;
  align-items: center;
}
.below {
  display: flex;
}
.calendar {
  margin-left: px(10);
}
.cityProvince {
  margin-left: px(10);
}
::v-deep .cityProvince .el-input--small .el-input__inner {
  width: px(220);
}

.calendarone {
  display: flex;
  margin-left: px(10);
}
.seachone {
  margin-left: px(10);
}
.sheet {
  height: px(499);
  border-radius: px(10);
  background-color: #fff;
  margin-bottom: px(23);
}

.diagram {
  height: px(499);
  display: flex;
  margin-bottom: px(20);
}
.diagramLift {
  width: px(466);
  height: px(499);
  background-color: #fff;
  border-right: 1px solid #e6eaf4ff;
}
.diagramRight {
  width: px(1552);
  padding: 0 16px 0 25px;
  height: px(499);
  background-color: #fff;
  position: relative;
  .title {
    font-size: 18px;
    height: 34px;
    font-weight: bold;
    color: #2d354c;
    margin-bottom: 10px;
  }
  .timeTab {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 157px;
    height: 34px;
    border-radius: 100px;
    border: 1px solid #1acb64;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    cursor: pointer;
    overflow: hidden;
    li {
      width: 50%;
      height: 100%;
      text-align: center;
      line-height: 34px;
      color: #1acb64;
    }
    .activeBtn {
      color: #ffffff;
      background: #1acb64;
    }
    .colorCla{
      background: #F73A75;
    }
  }
}
</style>
