<template>
  <div class="SamplesLineEchart">
    <div :id="id" class="echarts"></div>
  </div>
</template>
<script>
import echartsMixins from "@/assets/js/echartsMixins.js"
export default {
  props: ['id', 'ehartData', 'colorList', 'total','markLineColor'],
  mixins: [echartsMixins],
  data() {
    return {
      myChart: '',
    }
  },
  watch: {
    ehartData() {
      this.initEcharts()
    },
  },
  mounted() {
    this.myChart = this.$echarts.init(document.getElementById(this.id))
    this.initEcharts()
  },

  methods: {
    // 刷新组件
    handlerResize(){
      this.myChart.resize()
    },
    initEcharts() {
      let yList = [...this.ehartData.yData,this.ehartData.his_positive];
      let max = (Math.max(...yList) + 2).toFixed(2),
          min = (Math.min(...yList) - 2).toFixed(2);
      max = max>100?100:max
      min = min<0?0:min

      var option = {
        grid: {
          show: true,
          left: '8%',
          right: '1%',
          top: '3%',
          bottom: '10%',
        },
        xAxis: {
          data: this.ehartData.xData,

          axisTick: {
            show: false,
          },
          axisLabel: {
            textStyle: {
              color:'#9AA1A9'
            },
          },
          splitLine: {
            show: true, //隐藏或显示
            lineStyle: {
              color: 'rgba(215, 215, 215, 1)',
              type: 'dashed', //设置网格线类型 dotted：虚线   solid:实线
              width: 0.5,
            },
          },
          axisLine: {
            show:false
          },
        },
        yAxis: {
          type: 'value',
          max,
          min,
          axisTick: {
            show: false,
          },
          axisLabel: {
            formatter: '{value}%',
            textStyle: {
              color:"rgba(215, 215, 215, 1)",
            },
          },
          axisLine: {
            lineStyle: {
              color: '#D7D7D7',
              type: 'dashed',
            },
          },
          splitLine: {
            lineStyle: {
              type: 'dashed', //设置网格线类型 dotted：虚线   solid:实线
              color: 'rgba(215, 215, 215, 1)',
              width: 0.5,
            },
          },
        },
        tooltip: {
          trigger: 'axis', // axis   item   none三个值
        },
        series: [
          {
            name: '历史',
            data: this.ehartData.yData,
            type: 'line',
            color: '#00AFE9',
            markLine: {
              symbol: 'none',
              silent: true,
              label: {
                color: this.markLineColor,
              },
              lineStyle: {
                color: this.markLineColor,
              },
              data: [
                {
                  name: '历史',
                  yAxis: this.ehartData.his_positive,
                  label: {
                    position: 'insideStartTop',
                    formatter: '{c}%',
                  },
                },
              ],
            },
          },
        ],
      }
      this.myChart.setOption(option)
    },
  },
}
</script>
<style lang="scss" scoped>
.SamplesLineEchart {
  width: 100%;
  height: px(390);
  .echarts {
    width: 100%;
    height: px(380);
  }
}
</style>